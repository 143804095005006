.App {
  background-color: #659be0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  padding: 4rem 1rem;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    img {
      max-width:150px;
      max-height: 150px;
      border-radius: 30%;
    }
    h1 {
      color: white;
      font-weight: bolder;
      font-size: 1.8rem;
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    .link-anchor {
      text-decoration: none;
    }

    .link-card {
      cursor: pointer;
      
      .card-body {
        text-align: center;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        img {
          max-width: 30px;
          max-height: 30px;
        }
      }

      &:hover {
        transform: scale(1.02);
      }
    }
  }
}